<template>
  <div class="columns">
    <div class="column is-12">
      <div class="box">
        <article v-if="mType === -1" class="message is-info">
          <div class="message-body">Select a statistics below</div>
        </article>

        <div v-if="showSelect" class="select">
          <select v-model="mType" @change="getData">
            <option :value="-1" :selected="mType === -1">Pick a query</option>
            <option
              v-if="meetingtypes.includes(1)"
              :value="0"
              :selected="mType === 0"
              >Meetingspace nr. of reservations</option
            >
            <option
              v-if="meetingtypes.includes(2)"
              :value="1"
              :selected="mType === 1"
              >Workspace nr. of reservations</option
            >
            <option :value="2" :selected="mType === 2"
              >Fee invoices revenue</option
            >
            <option
              v-if="meetingtypes.includes(1)"
              :value="3"
              :selected="mType === 3"
              >Meetingspace nr. of search</option
            >
            <option
              v-if="meetingtypes.includes(2)"
              :value="4"
              :selected="mType === 4"
              >Workspace nr. of search</option
            >
            <option
              v-if="meetingtypes.includes(1)"
              :value="5"
              :selected="mType === 5"
              >Meetingspace revenue</option
            >
            <option
              v-if="meetingtypes.includes(2)"
              :value="6"
              :selected="mType === 6"
              >Workspace revenue</option
            >
            <option
              v-if="meetingtypes.includes(1)"
              :value="7"
              :selected="mType === 7"
              >Meetingspace nr. of seats</option
            >
            <option
              v-if="meetingtypes.includes(2)"
              :value="8"
              :selected="mType === 8"
              >Workspace nr. of seats</option
            >
          </select>
        </div>
        <div v-if="isLoading">
          <ui-loader />
        </div>

        <div v-if="!isLoading">
          <header v-if="showSelect" class="card-header">
            <p class="card-header-title">{{ title }}</p>
          </header>
          <div class="card-table">
            <div class="content">
              <table class="table is-fullwidth is-striped is-narrow">
                <thead>
                  <tr>
                    <th width="5%">&nbsp;</th>
                    <th>
                      <div class="columns">
                        <span
                          v-for="(val, index) in years"
                          :key="index"
                          class="column has-text-right is-size-6"
                          >{{ val.Year }}</span
                        >
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(month, index) in data" :key="index">
                    <td width="5%" class="has-text-weight-bold is-size-6">
                      {{
                        Number(month.Month) === 13
                          ? 'Total'
                          : month.Month | getMonthName
                      }}
                    </td>
                    <td>
                      <div class="columns is-size-6">
                        <span
                          v-for="(year, index) in month.Years"
                          :key="index"
                          class="column has-text-right is-size-6"
                          :class="{
                            'has-text-weight-bold': Number(month.Month) === 13,
                          }"
                        >
                          <span v-if="!valueIsCurrency">{{ year.Value }}</span>
                          <span v-if="valueIsCurrency">{{
                            year.Value | toCurrency
                          }}</span>

                          <span
                            :class="{
                              'has-text-success': year.Percentage > 0,
                              'has-text-danger': year.Percentage < 0,
                            }"
                          >
                            <span
                              class="tooltip"
                              :data-tooltip="year.Percentage + '%'"
                              v-if="
                                year.Percentage > 0 && Number(month.Month) < 13
                              "
                            >
                              <span class="icon has-text-success">
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-up']"
                                />
                              </span>
                            </span>

                            <span
                              class="tooltip"
                              :data-tooltip="year.Percentage + '%'"
                              v-if="
                                year.Percentage < 0 && Number(month.Month) < 13
                              "
                            >
                              <span class="icon has-text-danger">
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-down']"
                                />
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stats from '@/providers/stats'

export default {
  props: {
    channelId: {
      default: 0,
      type: Number,
    },

    locationId: {
      default: 0,
      type: Number,
    },

    type: {
      default: -1,
      type: Number,
    },

    showSelect: {
      type: Boolean,
      default: true,
    },

    meetingtypes: {
      type: Array,
      default: () => [1, 2],
    },
  },

  data() {
    return {
      data: [],
      isLoading: false,
      mType: this.type,
      title: '',
      valueIsCurrency: false,
    }
  },

  computed: {
    years() {
      let years = []

      if (typeof this.data !== 'undefined' && this.data.length > 0) {
        years = this.data[0].Years
      }

      return years
    },
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      let self = this
      self.data = []
      self.title = ''

      if (self.mType > -1) {
        self.isLoading = true
      }

      switch (self.mType) {
        case 0: // MEETINGSPACE RESERVATIONS
          self.title = 'Meetingspace nr. of reservations'
          self.valueIsCurrency = false
          stats.methods
            .getReservationsPerYearPerMonth(1, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 1: // WORKSPACE RESERVATIONS
          self.title = 'Workspace nr. of reservations'
          self.valueIsCurrency = false
          stats.methods
            .getReservationsPerYearPerMonth(2, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 2: // FEE INVOICES
          self.title = 'Fee invoices revenue'
          self.valueIsCurrency = true
          self.nrOfDecimals = 2
          self.prefix = '&euro;'

          stats.methods
            .getFeeInvoiceTotalPerYearPerMonth(this.channelId, this.locationId)
            .then((data) => {
              this.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 3: // MEETINGSPACE SEARCH QUERIES
          this.title = 'Meetingspace search queries'
          self.valueIsCurrency = false
          stats.methods
            .getSearchQueriesPerYearPerMonth(1, this.channelId, this.locationId)
            .then((data) => {
              this.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 4: // WORKSPACE SEARCH QUERIES
          self.title = 'Workspace search queries'
          self.valueIsCurrency = false
          stats.methods
            .getSearchQueriesPerYearPerMonth(2, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 5: // MEETINGSPACE REVENUE QUERIES
          self.title = 'Meetingspace revenue all locations'
          self.valueIsCurrency = true
          stats.methods
            .getRevenuePerYearPerMonth(1, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 6: // WORKSPACE REVENUE QUERIES
          self.title = 'Workspace revenue all locations'
          self.valueIsCurrency = true
          stats.methods
            .getRevenuePerYearPerMonth(2, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })

          break
        case 7: // MEETINGSPACE SEATS QUERIES
          self.title = 'Meetingspace nr. of seats'
          self.valueIsCurrency = false
          stats.methods
            .getSeatsPerYearPerMonth(1, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
        case 8: // WORKSPACE SEATS QUERIES
          self.title = 'Workspace nr. of seats'
          self.valueIsCurrency = false
          stats.methods
            .getSeatsPerYearPerMonth(2, this.channelId, this.locationId)
            .then((data) => {
              self.data = data
            })
            .finally(() => {
              self.isLoading = false
            })

          break
      }
    },
  },
}
</script>

<style scoped>
.glowing-pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: glowing-pulse 2s infinite;
}

@keyframes glowing-pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
